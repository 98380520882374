module.exports = {
    "bank_card_list": "วิธีการชำระเงิน",
    "bank_card_add": "เพิ่มวิธีการชำระเงินใหม่",
    "payment_instrument_list": "บันทึกวิธีการชำระเงิน",
    "cancel_text": "ยกเลิก",
    "submit_text": "ส่ง",
    "not_found_text": "ไม่พบวิธีการชำระเงิน",
    "card_holder_name": "ชื่อผู้ถือบัตร",
    "card_number": "หมายเลขบัตร",
    "expiry_date": "วันหมดอายุ",
    "cvv": "CVV",
    "card_text": "บัตร",
    "bank_text": "ธนาคาร",
    "save_card": "บันทึกบัตร",
    "save_bank": "บันทึกบัญชีธนาคาร",
    "account_name": "ชื่อบัญชี",
    "account_number": "หมายเลขบัญชี",
    "routing_number": "หมายเลขเราติ้ง",
    "account_type": "ประเภทบัญชี",
    "select": "เลือก",
    "account_holder_address": "ที่อยู่เจ้าของบัญชี",
    "city": "เมือง",
    "state": "จังหวัด",
    "country": "ประเทศ",
    "zipcode": "รหัสไปรษณีย์",
    "credit_points": "คะแนนเครดิต",
    "current_balance": "ยอดเงินปัจจุบัน",
    "invalidAccountName": "กรุณากรอกชื่อบัญชีที่ถูกต้อง",
    "cardName": "ชื่อบนบัตร",
    "bankName": "ชื่อธนาคาร",
    "cardType": "ประเภทบัตร",
    "requiredError": "ต้องระบุข้อมูลในช่องนี้",
    "payment_instruments":"เครื่องมือการชำระเงิน",
    "reset_text":"รีเซ็ต",
    "uwallet":"UWallet",
    "uwalletFund":"การเติมเงิน UWallet",
    "validCreditPoints":"โปรดป้อนคะแนนเครดิตที่ถูกต้อง",
    "maxCreditPoint":"คะแนนเครดิตสูงสุด",
    "max_amount":"จำนวนเงินสูงสุด",
    "validAmountText":"โปรดป้อนจำนวนเงินที่ถูกต้อง",
    "SessionExpired":"หมดเวลาเซสชัน",
    "InvalidRequest":"คำขอไม่ถูกต้อง",
    "uwalletAmountCheckText":"โปรดป้อนจำนวนเงิน UWallet ที่ถูกต้อง",
    "uwalletFundAmountCheckText":"โปรดป้อนจำนวนเงินที่เติมเงิน UWallet ที่ถูกต้อง",
    "paymentMethodErrorText":"โปรดเลือกวิธีการชำระเงิน",
    "autoshipFailTxt":"ไม่สามารถสร้างการจัดส่งอัตโนมัติได้!",
    "wentWrongText":"เกิดข้อผิดพลาดบางอย่าง โปรดลองอีกครั้งในภายหลัง",
    "cardAddFailText":"ไม่สามารถเพิ่มบัตรได้",
    "cardAddSuccess":"เพิ่มบัตรเรียบร้อยแล้ว",
    "bankPageLoadFail":"ไม่สามารถโหลดหน้าเพิ่มธนาคารได้!",
    "bankAddFailed":"ไม่สามารถเพิ่มบัญชีธนาคารได้",
    "bankAddSuccess":"เพิ่มบัญชีธนาคารเรียบร้อยแล้ว",
    "accountNamePlaceholder":"โปรดป้อนชื่อบัญชี",
    "accountNumberPlaceholder":"โปรดป้อนหมายเลขบัญชี",
    "routingNumberPlaceholder":"โปรดป้อนหมายเลขเส้นทาง",
    "invalidAccountNumber":"โปรดป้อนหมายเลขบัญชีที่ถูกต้อง",
    "invalidRoutingNumber":"โปรดป้อนหมายเลขเส้นทางที่ถูกต้อง",
    "cardAddPageFail":"ไม่สามารถโหลดหน้าเพิ่มบัตรได้!",
    "cardNameNull":"ต้องระบุชื่อบัตร",
    "plsEnterValidName":"โปรดป้อนชื่อที่ถูกต้อง",
    "InvalidCardNumber":"หมายเลขบัตรไม่ถูกต้อง",
    "InvalidExpirationDate":"วันที่หมดอายุไม่ถูกต้อง",
    "InvalidCVV":"CVV ไม่ถูกต้อง",
    "accountalreadyExists":"บัญชีมีอยู่แล้ว",
    "bnakNotFound":"ไม่พบธนาคาร",
    "maxAccNum":"หมายเลขบัญชีต้องไม่เกิน 20 หลัก",
    "minAccNum":"หมายเลขบัญชีต้องมีอย่างน้อย 4 หลัก",
    "accNumber":"โปรดป้อนหมายเลขบัญชี.",
    "minRoutingNumber":"หมายเลขเส้นทางต้องมีอย่างน้อย 9 หลัก.",
    "routingNum":"โปรดป้อนหมายเลขเส้นทาง.",
    "Acctype":"โปรดเลือกประเภทบัญชี.",
    "checking":"เช็ค",
    "saving":"ออม",
    "userType":"ประเภทผู้ใช้",
    "usertypeErr":"โปรดเลือกประเภทผู้ใช้.",
    "validzipCode":"โปรดป้อนรหัสไปรษณีย์ที่ถูกต้อง",
    "stateNameinvalid":"โปรดป้อนชื่อรัฐที่ถูกต้อง",
    "validAddress":"โปรดป้อนที่อยู่ที่ถูกต้อง.",
    "maxAddress":"ที่อยู่ต้องไม่เกิน 400 ตัวอักษร.",
    "minAddress":"ที่อยู่ต้องมีอย่างน้อย 5 ตัวอักษร",
    "cityRequired":"โปรดป้อนชื่อเมืองของคุณ.",
    "maxCity":"ชื่อเมืองต้องไม่เกิน 60 ตัวอักษร.",
    "countryNameInvalid":"โปรดเลือกชื่อประเทศ.",
    "selectPayment":"เลือกวิธีการชำระเงิน",
    "back":"ย้อนกลับ",
    "ccintl":"บัตรเครดิตนานาชาติ",
    "amex_dis":"Amex/Discover",
    "amex_card_text":"เพิ่มบัตร AMEX",
    "mater_visa":"MasterCard/Visa",
    "loadingText":"กำลังโหลด..., โปรดรอ!",
    "add_card_text":"เพิ่มบัตรเครดิต",
    "credit_card":"บัตรเครดิต",
    "cardUpdatedSuccessfully":"อัปเดตบัตรเรียบร้อยแล้ว",
    "reason":"เหตุผล",
    "failedUpdateCard":"ไม่สามารถอัปเดตบัตรได้",
    "failedDuplicateCard": "Card addition failed as card already exist."
}