module.exports = {
    "bank_card_list": "付款方式",
    "bank_card_add": "新增付款方式",
    "payment_instrument_list": "已存付款方式",
    "cancel_text": "取消",
    "submit_text": "提交",
    "not_found_text": "未找到付款方式",
    "card_holder_name": "持卡人姓名",
    "card_number": "卡號",
    "expiry_date": "有效日期",
    "cvv": "安全代碼",
    "card_text": "信用卡",
    "bank_text": "銀行",
    "save_card": "儲存卡片",
    "save_bank": "儲存銀行帳戶",
    "account_name": "帳戶名稱",
    "account_number": "帳號",
    "routing_number": "路由號碼",
    "account_type": "帳戶類型",
    "select": "選擇",
    "account_holder_address": "帳戶持有人地址",
    "city": "城市",
    "state": "州",
    "country": "國家",
    "zipcode": "郵遞區號",
    "payment_instruments": "支付工具",
    "reset_text": "重置",
    "credit_points": "学分",
    "current_balance": "当前余额",
    "uwallet": "Uwallet 獎金帳戶",
    "uwalletFund": "Uwallet 加值帳戶",
    // new keyWords ------
    "validCreditPoints": "請輸入有效積分",
    "maxCreditPoint": "最高學分",
    "max_amount": "最高金額",
    "validAmountText": "請輸入有效金額",
    "SessionExpired": "會話過期",
    "InvalidRequest": "無效的請求",
    "uwalletAmountCheckText": "請輸入有效的 UWallet 金額",
    "uwalletFundAmountCheckText": "請輸入有效的 UWallet 資金金額",
    "paymentMethodErrorText": "請選擇任何付款方式",
    "autoshipFailTxt": "無法創建自動送貨！",
    "wentWrongText": "出了點問題",
    "cardAddFailText": "請稍後再試。",
    "cardAddSuccess": "添加卡失敗",
    "bankPageLoadFail": "添加卡成功",
    "bankAddFailed": "無法加載銀行添加頁面！。",
    "bankAddSuccess": "添加銀行失敗",
    "accountNamePlaceholder": "銀行添加成功",
    "accountNumberPlaceholder": "請輸入賬戶名",
    "routingNumberPlaceholder": "請輸入賬號",
    "invalidAccountName": "請輸入路由號碼",
    "invalidAccountNumber": "請輸入有效的賬戶名",
    "invalidRoutingNumber": "請輸入有效帳號",
    "cardAddPageFail": "請輸入有效的路由號碼",
    "cardName": "未能加載卡添加頁面！。",
    "cardNameNull": "卡片上的名字",
    "plsEnterValidName": "需要卡名",
    "InvalidCardNumber": "請輸入有效名稱",
    "InvalidExpirationDate": "無效卡號",
    "InvalidCVV": "失效日期無效",
    "accountalreadyExists": "無效的 CVV",
    "bnakNotFound": "用戶名已經被註冊",
    "maxAccNum": "找不到銀行",
    "minAccNum": "帳號不能超過 20 位。",
    "accNumber": "賬號不能少於4位。",
    "minRoutingNumber": "請輸入帳號。",
    "routingNum": "路由號碼不能少於 9 位。",
    "bankName": "請輸入路由號碼。",
    "Acctype": "銀行名",
    "checking": "請選擇賬戶類型。",
    "saving": "檢查",
    "userType": "保存",
    "usertypeErr": "用戶類型",
    "validAddress": "請選擇用戶類型。",
    "maxAddress": "請輸入有效地址。",
    "minAddress": "地址不能超過 400 個字符。",
    "validzipCode": "地址不能少於5個字符",
    "stateNameinvalid": "請輸入有效的郵政編碼",
    "cityRequired": "請輸入有效的州",
    "maxCity": "請輸入您所在的城市。",
    "countryNameInvalid": "城市不能超過 60 個字符。",
    "selectPayment": "選擇付款方式",
    "back": "後退",
    "cardType": "卡的種類",
    "ccintl": "國際信用卡",
    "amex_dis": "美國運通/探索卡",
    "amex_card_text": "添加美國運通卡",
    "mater_visa": "萬事達卡/Visa卡",
    "loadingText": "加載請稍候！",
    "unionpay": "銀聯卡",
    "add_card_text": "添加信用卡",
    "credit_card": "信用卡",
    "cardUpdatedSuccessfully": "卡更新成功",
    "reason": "原因",
    "failedUpdateCard": "卡更新失败",
    "requiredError": "必填欄位",
    "failedDuplicateCard": "Card addition failed as card already exist."
}