module.exports = {
    "bank_card_list": "Métodos de pago",
    "bank_card_add": "Agregar nuevos métodos de pago",
    "payment_instrument_list": "Métodos de pago guardados",
    "cancel_text": "Cancelar",
    "submit_text": "Enviar",
    "not_found_text": "No se encontró ningún método de pago",
    "card_holder_name": "Nombre del titular de la tarjeta",
    "card_number": "Número de la tarjeta",
    "expiry_date": "Fecha de caducidad",
    "cvv": "CVV",
    "card_text": "Tarjeta",
    "bank_text": "Banco",
    "save_card": "Guardar tarjeta",
    "save_bank": "Guardar cuenta bancaria",
    "account_name": "Nombre de la cuenta",
    "account_number": "Número de cuenta",
    "routing_number": "Número de ruta",
    "account_type": "Tipo de cuenta",
    "select": "Seleccionar",
    "account_holder_address": "Dirección del titular de la cuenta",
    "city": "Ciudad",
    "state": "Estado",
    "country": "País",
    "zipcode": "Código postal",
    "payment_instruments": "Instrumentos de pago",
    "reset_text": "Reiniciar",
    "credit_points": "Puntos de crédito",
    "current_balance": "Saldo actual",
    "uwallet": "UWallet",
    "uwalletFund": "Financiamiento de UWallet",
    // new keyWords ------
    "validCreditPoints": "Ingrese puntos de crédito válidos",
    "maxCreditPoint": "Puntos de crédito máximos",
    "max_amount": "Importe máximo",
    "validAmountText": "Por favor ingrese una cantidad válida",
    "SessionExpired": "Sesión expirada",
    "InvalidRequest": "Solicitud no válida",
    "uwalletAmountCheckText": "Ingrese una cantidad válida de UWallet",
    "uwalletFundAmountCheckText": "Ingrese un monto válido de financiación de UWallet",
    "paymentMethodErrorText": "Por favor seleccione cualquier método de pago",
    "autoshipFailTxt": "¡No se pudo crear el envío automático!",
    "wentWrongText": "Algo salió mal, inténtalo de nuevo más tarde.",
    "cardAddFailText": "No se pudo agregar la tarjeta",
    "cardAddSuccess": "Tarjeta añadida con éxito",
    "bankPageLoadFail": "¡Error al cargar el banco agregar página!.",
    "bankAddFailed": "No se pudo agregar el banco",
    "bankAddSuccess": "Banco añadido con éxito",
    "accountNamePlaceholder": "Por favor ingrese el nombre de la cuenta",
    "accountNumberPlaceholder": "Por favor ingrese número de cuenta",
    "routingNumberPlaceholder": "Ingrese el número de ruta",
    "invalidAccountName": "Ingrese un nombre de cuenta válido",
    "invalidAccountNumber": "Por favor, introduzca un número de cuenta válido",
    "invalidRoutingNumber": "Ingrese un número de ruta válido",
    "cardAddPageFail": "¡Error al cargar la tarjeta agregar página!.",
    "cardName": "Nombre en la tarjeta",
    "cardNameNull": "Se requiere el nombre de la tarjeta",
    "plsEnterValidName": "Por favor, introduzca un nombre válido",
    "InvalidCardNumber": "Numero de tarjeta invalido",
    "InvalidExpirationDate": "Fecha de expiracion inválida",
    "InvalidCVV": "CVV no válido",
    "accountalreadyExists": "La cuenta ya existe",
    "bnakNotFound": "Bank Not Found",
    "maxAccNum": "El número de cuenta no puede tener más de 20 dígitos.",
    "minAccNum": "El número de cuenta no puede tener menos de 4 dígitos.",
    "accNumber": "Por favor ingrese el Número de Cuenta.",
    "minRoutingNumber": "El número de ruta no puede tener menos de 9 dígitos.",
    "routingNum": "Ingrese el número de ruta.",
    "bankName": "Nombre del banco",
    "Acctype": "Seleccione un tipo de cuenta.",
    "checking": "Comprobación",
    "saving": "Ahorro",
    "userType": "Tipo de usuario",
    "usertypeErr": "Seleccione un Tipo de usuario.",
    "validAddress": "Por favor introduce una dirección válida.",
    "maxAddress": "La dirección no puede tener más de 400 caracteres.",
    "minAddress": "La dirección no puede tener menos de 5 caracteres",
    "validzipCode": "Por favor ingrese un código postal válido",
    "stateNameinvalid": "Ingrese un estado válido",
    "cityRequired": "Por favor ingrese su ciudad.",
    "maxCity": "La ciudad no puede tener más de 60 caracteres.",
    "countryNameInvalid": "Seleccione un nombre de país",
    "selectPayment": "Seleccionar método de pago",
    "back": "Atrás",
    "cardType": "Tipo de tarjeta",
    "ccintl": "Tarjeta de crédito internacional",
    "amex_dis": "Amex/Descubrir",
    "amex_card_text": "Añadir tarjeta AMEX",
    "mater_visa": "MasterCard/Visa",
    "add_card_text": "Añadir tarjeta de crédito",
    "loadingText": "¡Cargando por favor espere!",
    "credit_card": "Tarjeta de crédito",
    "cardUpdatedSuccessfully": "Tarjeta actualizada exitosamente",
    "reason": "Razón",
    "failedUpdateCard": "No se pudo actualizar la tarjeta",
    "requiredError": "Este campo es obligatorio",
    "failedDuplicateCard": "Card addition failed as card already exist."
}