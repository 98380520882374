module.exports = {
    "bank_card_list": "Kaedah Bayaran",
    "bank_card_add": "Tambah Kaedah Bayaran Baharu",
    "payment_instrument_list": "Kaedah Bayaran Disimpan",
    "cancel_text": "Batal",
    "submit_text": "Hantar",
    "not_found_text": "Tiada Kaedah Bayaran Ditemui",
    "card_holder_name": "Nama Pemegang Kad",
    "card_number": "Nombor Kad",
    "expiry_date": "Tarikh Tamat Tempoh",
    "cvv": "CVV",
    "card_text": "Kad",
    "bank_text": "Bank",
    "save_card": "Simpan Kad",
    "save_bank": "Simpan Akaun Bank",
    "account_name": "Nama Akaun",
    "account_number": "Nombor Akaun",
    "routing_number": "Nombor Penghalaan",
    "account_type": "Jenis Akaun",
    "select": "Pilih",
    "account_holder_address": "Alamat Pemegang Akaun",
    "city": "Bandar",
    "state": "Negeri",
    "country": "Negara",
    "zipcode": "Poskod",
    "payment_instruments": "Instrumen Pembayaran",
    "reset_text": "Tetapkan semula",
    "credit_points": "Mata Kredit",
    "current_balance": "Baki terkini",
    "uwallet": "UWallet",
    "uwalletFund": "Pembiayaan dompet U",
    // new keyWords ------
    "validCreditPoints": "Sila masukkan Mata Kredit yang sah",
    "maxCreditPoint": "Mata Kredit Maksimum",
    "max_amount": "Jumlah Maksimum",
    "validAmountText": "Sila masukkan jumlah yang sah",
    "SessionExpired": "Sesi Tamat",
    "InvalidRequest": "Permintaan tidak sah",
    "uwalletAmountCheckText": "Sila masukkan jumlah UWallet yang sah",
    "uwalletFundAmountCheckText": "Sila masukkan jumlah Pembiayaan UWallet yang sah",
    "paymentMethodErrorText": "Sila pilih mana-mana kaedah pembayaran",
    "autoshipFailTxt": "Gagal membuat autoship!",
    "wentWrongText": "Ada yang tidak kena",
    "cardAddFailText": "Sila cuba sebentar lagi.",
    "cardAddSuccess": "Gagal menambah kad",
    "bankPageLoadFail": "Kad Berjaya Ditambah",
    "bankAddFailed": "Gagal Memuatkan Halaman tambah Bank!.",
    "bankAddSuccess": "Gagal menambah Bank",
    "accountNamePlaceholder": "Bank berjaya ditambahkan",
    "accountNumberPlaceholder": "Sila masukkan nama akaun",
    "routingNumberPlaceholder": "Sila masukkan nombor akaun",
    "invalidAccountName": "Sila masukkan nombor penghalaan",
    "invalidAccountNumber": "Sila masukkan nama akaun yang sah",
    "invalidRoutingNumber": "Sila masukkan nombor akaun yang sah",
    "cardAddPageFail": "Sila masukkan nombor laluan yang sah",
    "cardName": "Gagal Memuatkan Halaman tambah Kad!.",
    "cardNameNull": "Nama pada kad",
    "plsEnterValidName": "Nama Kad diperlukan",
    "InvalidCardNumber": "Sila masukkan nama yang sah",
    "InvalidExpirationDate": "Nombor kad tidak sah",
    "InvalidCVV": "Tarikh Luput Tidak Sah",
    "accountalreadyExists": "CVV tidak sah",
    "bnakNotFound": "Akaun telah wujud",
    "maxAccNum": "Bank Tidak Ditemui",
    "minAccNum": "Nombor akaun tidak boleh melebihi 20 digit.",
    "accNumber": "Nombor akaun tidak boleh kurang daripada 4 digit.",
    "minRoutingNumber": "Sila masukkan Nombor Akaun.",
    "routingNum": "Nombor penghalaan tidak boleh kurang daripada 9 digit.",
    "bankName": "Sila masukkan Nombor Penghalaan.",
    "Acctype": "Nama bank",
    "checking": "Sila pilih Jenis Akaun.",
    "saving": "Menyemak",
    "userType": "Menyimpan",
    "usertypeErr": "Jenis pengguna",
    "validAddress": "Sila pilih Jenis Pengguna.",
    "maxAddress": "Sila masukkan alamat yang sah.",
    "minAddress": "Alamat tidak boleh melebihi 400 Aksara.",
    "validzipCode": "Alamat tidak boleh kurang daripada 5 Aksara",
    "stateNameinvalid": "Sila masukkan Kod Pos yang sah",
    "cityRequired": "Sila masukkan Negeri yang sah",
    "maxCity": "Sila masukkan bandar anda.",
    "countryNameInvalid": "Bandar tidak boleh melebihi 60 Aksara.",
    "selectPayment": "Pilih Kaedah Pembayaran",
    "back": "belakang",
    "cardType": "Jenis kad",
    "ccintl": "Kad Kredit Antarabangsa",
    "amex_dis": "Amex/Discover",
    "amex_card_text": "Tambah Kad AMEX",
    "mater_visa": "Mastercard/Visa",
    "add_card_text": "Tambah Kad Kredit",
    "loadingText": "Muat turun sila tunggu!",
    "credit_card": "Kad kredit",
    "cardUpdatedSuccessfully": "Kad berjaya dikemas kini",
    "reason": "Sebab",
    "failedUpdateCard": "Gagal mengemas kini kad",
    "requiredError": "Ruangan Perlu Diisi",
    "failedDuplicateCard": "Card addition failed as card already exist."
}